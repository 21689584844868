export default [
    {
        i18n: {
            label: 'Easy',
            label_no: 'Lett',
            text: 'Remember 20 items'
        },
        sessionOptions: {
            numberOfItems: 20,
            // numberOfFakeItems: 1,
            includeQuantity: false,
            includePrice: false,
            itemMemorizeTimeLimit: 1000,
            itemQuizTimeLimit: 1000
        }
    },
    {
        i18n: {
            label: 'Medium',
            label_no: 'Middels',
            text: 'Remember 20 items with prices'
        },
        sessionOptions: {
            numberOfItems: 20,
            // numberOfFakeItems: 1,
            includeQuantity: false,
            includePrice: true,
            itemMemorizeTimeLimit: 1000,
            itemQuizTimeLimit: 1000
        }
    },
    {
        i18n: {
            label: 'Hardcore',
            label_no: 'Vanskelig',
            text: 'Remember 35 items and prices'
        },
        sessionOptions: {
            numberOfItems: 35,
            // numberOfFakeItems: 1,
            includeQuantity: false,
            includePrice: true,
            itemMemorizeTimeLimit: 1000,
            itemQuizTimeLimit: 1000
        }
    }
];
